import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import contactService from "../services/contactService";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

export const handleRequestDemo = createAsyncThunk(
  "contact/handleRequestDemo",
  async (data, thunkAPI) => {
    try {
      const response = await contactService.requestDemo(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    status: STATUSES.IDLE,
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleRequestDemo.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleRequestDemo.fulfilled, (state, action) => {
        showAllNotifications(action.payload.message, ToastColors.success);
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(handleRequestDemo.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default contactSlice.reducer;
