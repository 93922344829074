import React, { useState } from "react";
import ProjectsGridView from "../../pages/projects/projectsGridView";
import ProjectListView from "../../pages/projects/projectsListView";
import RenameProjectModal from "./RenameProjectModal";
import DeleteProjectModal from "./DeleteProjectModal";
import { showAllNotifications } from "../../utils/notificationHelper";
import {
  handleDeleteProject,
  handleUpdateProject,
} from "../../redux/slices/projectSlice";
import ToastColors from "../../constants/toastColors";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../utils/errorHandler";
import { useNavigate } from "react-router-dom";
import { notifications } from "../../utils/notificationConstants";

const ProjectsView = ({ data, handleOpenProject, isGridView }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateProjectId, setUpdateProjectId] = useState(null);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleOpenRenameModal = (e, id) => {
    e.stopPropagation();
    setUpdateProjectId(id);
    setIsRenameModalOpen(true);
  };

  const handleOpenDeleteModal = (e, id) => {
    e.stopPropagation();
    setDeleteProjectId(id);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsRenameModalOpen(false);
    setIsDeleteModalOpen(false);
    setUpdateProjectId(null);
    setDeleteProjectId(null);
  };

  const handleSaveProject = (newName) => {
    if (!newName.trim()) {
      showAllNotifications("Project name cannot be blank");
      return;
    }
    const data = {
      project: {
        name: newName,
      },
    };

    dispatch(handleUpdateProject({ id: updateProjectId, data }))
      .unwrap()
      .then(() => {
        showAllNotifications(
          notifications.projectUpdatedSuccessfully,
          ToastColors.success
        );
        handleCloseModal();
      })
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  };

  const deleteProject = () => {
    dispatch(handleDeleteProject(deleteProjectId))
      .unwrap()
      .then(() => {
        showAllNotifications(
          "Project deleted successfully",
          ToastColors.success
        );
        handleCloseModal();
      })
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  };

  const duplicateProject = (e, project) => {
    e.stopPropagation();
    navigate("/projects/new", { state: { project, type: "Duplicate" } });
  };

  return (
    <>
      {isRenameModalOpen && (
        <RenameProjectModal
          resourceName="Project"
          placeholder="Enter new project name"
          onClose={handleCloseModal}
          onSave={handleSaveProject}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteProjectModal
          onClose={handleCloseModal}
          deleteProject={deleteProject}
        />
      )}

      {data && isGridView ? (
        <ProjectsGridView
          data={data}
          handleOpenProject={handleOpenProject}
          handleOpenRenameModal={handleOpenRenameModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          duplicateProject={duplicateProject}
        />
      ) : (
        <ProjectListView
          data={data}
          handleOpenProject={handleOpenProject}
          handleOpenRenameModal={handleOpenRenameModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          duplicateProject={duplicateProject}
        />
      )}
    </>
  );
};

export default ProjectsView;
