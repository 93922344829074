import * as Yup from 'yup';

export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phone: Yup.string().required('Phone Number is required'),
  role: Yup.string().required('Role is required'),
  company: Yup.string().required('Company Name is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().required('Password is required')
});

export const NewProjectSchema = Yup.object().shape({
  projectName: Yup.string()
    .min(3, "Project name must be at least 3 characters")
    .required("Project name is required"),
  projectType: Yup.string().required("Project type is required"),
  clientName: Yup.string()
    .min(3, "Client name must be at least 3 characters")
    .required("Client name is required"),
  inviteEmail: Yup.string().email("Invalid email format"),
  projectImage: Yup.mixed().required("An image is required."),
  projectFiles: Yup.array().min(1, "At least one file is required."),
  preference: Yup.object().shape({
    items: Yup.array(),
    unitMeasurements: Yup.array(),
    unitOfAcreage: Yup.array(),
    acp: Yup.boolean(),
    acreage: Yup.boolean(),
    longitude: Yup.number().nullable(),
    latitude: Yup.number().nullable(),
  })
});

 export const AddUserSchema = Yup.object().shape({
   firstName: Yup.string().required("First name is required"),
   lastName: Yup.string().required("Last name is required"),
   email: Yup.string()
     .email("Invalid email format")
     .required("Email is required"),
   phone: Yup.string().required("Phone number is required"),
   role: Yup.string().required("User role is required"),
   password: Yup.string()
     .min(6, "Password must be at least 6 characters")
     .required("Password is required"),
   confirmPassword: Yup.string()
     .oneOf([Yup.ref("password"), null], "Passwords must match")
     .required("Confirm password is required"),
 });

 export const EditUserSchema = Yup.object().shape({
   firstName: Yup.string().required("First name is required"),
   lastName: Yup.string().required("Last name is required"),
   email: Yup.string()
     .email("Invalid email format")
     .required("Email is required"),
   phone: Yup.string().required("Phone number is required"),
   role: Yup.string().required("User role is required"),
   status: Yup.string()
     .oneOf(["active", "inactive"], "Status must be either active or inactive")
     .required("Status is required"),
 });

 export const requestDemoSchema = Yup.object().shape({
   name: Yup.string().required("Name is required"),
   email: Yup.string()
     .email("Invalid email format")
     .required("Email is required"),
   company: Yup.string().required("Company or Organization is required"),
   trade: Yup.string().required("Trade is required"),
   items: Yup.string().required("This field is required"),
 });
