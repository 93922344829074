import React, { useEffect, useState } from "react";
import { LoginImg, LogoImg } from "../../assets/images/images";
import { resetPasswordIcon } from "../../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { handleResetPassword } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
	const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleChange = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(handleResetPassword({ user: { email } }));
  };

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) navigate("/projects");
  }, [isAuthenticated, navigate]);

  return (
    <div className="w-full h-[100vh] px-12 py-14" style={{ backgroundImage: `url(${LoginImg})`, backgroundSize: "107%", backgroundPositionX: "right", backgroundPositionY: "top" }}>
      <div className="w-full">
        <img src={LogoImg} alt="VerixPro" />
      </div>
      <div className="w-full flex justify-center items-center">
        <form onSubmit={handleSubmit} className="w-full max-w-[450px] mt-20 bg-white p-10 rounded-lg">
          <div className="w-full mb-4 flex justify-center">
            <img src={resetPasswordIcon} alt="lock" />
          </div>
          <div className="w-full text-center text-2xl font-semibold pb-5 mb-5 border-b border-b-[1px_solid_#E4E4E7]">
            Reset Password
          </div>
          <div className="w-full mb-8 text-[#71717A]">
            Enter the email associated with your account and we will send an email with instructions to reset your password
          </div>
          <div className="w-full mb-4">
            <div className="w-full text-sm font-medium mb-2">
              Email <span className="text-red-500">*</span>
            </div>
            <input
              type="email"
              className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white text-sm h-10 flex justify-center items-center rounded-md"
          >
            Send Instructions
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
