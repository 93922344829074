import React from "react";
import Header from "./header";
import Footer from "./footer";
import { motion } from "framer-motion";
import Banner from "./banner";
import { bannerTexts } from "../../utils/constants/landingPageConstants";
import { MissionPicture } from "../../assets/images/images";

const AboutUs = () => {
  return (
    <div className="bg-gray-100">
      <Header />
      <Banner
        heading={bannerTexts.aboutUsPage.heading}
        text={bannerTexts.aboutUsPage.subHeading}
      />

      {/* About Us Section */}
      <motion.section
        className="py-16 px-8 mx-auto max-w-6xl space-y-16"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        {/* Founder's Journey */}
        <motion.div
          className="space-y-6"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <h3 className="text-2xl font-bold text-gray-800">
            Founder’s Journey
          </h3>
          <p className="text-gray-600">
            {bannerTexts.aboutUsPage.journeyText1}
          </p>
          <p className="text-gray-600">
            {bannerTexts.aboutUsPage.journeyText2}
          </p>
        </motion.div>

        {/* Vision & Innovation */}
        <motion.div
          className="bg-gray-50 rounded-lg p-8 shadow-lg space-y-6"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h3 className="text-2xl font-bold text-gray-800">
            Vision & Innovation
          </h3>
          <p className="text-gray-600">{bannerTexts.aboutUsPage.vision}</p>
        </motion.div>

        {/* Mission Statement */}
        <motion.div
          className="flex flex-col md:flex-row items-center gap-8"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="w-full md:w-1/2 space-y-4">
            <h3 className="text-2xl font-bold text-gray-800">Our Mission</h3>
            <p className="text-gray-600">{bannerTexts.aboutUsPage.mission}</p>
          </div>
          <img
            src={MissionPicture}
            alt="Innovation"
            className="rounded-lg shadow-lg w-full md:w-1/2"
          />
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default AboutUs;
