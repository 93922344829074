import React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  YoutubeIcon,
} from "../../assets/icons/icons";
import { footerText } from "../../utils/constants/landingPageConstants";

const Footer = () => {
  return (
    <>
      <motion.div
        className="text-center py-8"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      >
        <h3 className="text-3xl font-bold text-gray-800 my-6">Sign Up Today</h3>
        <p className="text-gray-600 mb-6">{footerText}</p>
        <NavLink to="/pricing">
          <button className="px-6 py-2 font-medium bg-black text-white w-fit transition-all shadow-[3px_3px_0px_white] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px]">
            Get Started
          </button>
        </NavLink>
      </motion.div>

      <motion.footer
        className="flex items-center justify-end py-4 bg-neutral-950 text-white"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
      >
        <div className="flex space-x-4 mr-8">
          <a
            href="https://www.youtube.com/@VerixPro"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={YoutubeIcon} alt="Youtube" className="h-6" />
          </a>
          <a
            href="https://www.linkedin.com/company/verixpro"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedInIcon} alt="LinkedIn" className="h-6" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61571770182317&mibextid=JRoKGi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FacebookIcon} alt="Facebook" className="h-6" />
          </a>
          <a
            href="https://www.instagram.com/verixpro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={InstagramIcon} alt="Instagram" className="h-6" />
          </a>
        </div>
      </motion.footer>
    </>
  );
};

export default Footer;
