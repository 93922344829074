import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className="flex items-center justify-between px-8 py-4 bg-white shadow-lg">
        <Link className="flex items-center space-x-2" to="/">
          <img src="/logo.png" alt="Logo" className="h-10" />
          <h1 className="text-xl font-bold text-gray-800">VerixPro</h1>
        </Link>
        <nav className="flex items-center space-x-6">
          <Link to="/" className="relative group text-gray-700">
            Home
            <span className="absolute left-0 bottom-[-2px] w-0 h-[2px] bg-black transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link to="/pricing" className="relative group text-gray-700">
            Pricing
            <span className="absolute left-0 bottom-[-2px] w-0 h-[2px] bg-black transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link to="/about-us" className="relative group text-gray-700">
            About Us
            <span className="absolute left-0 bottom-[-2px] w-0 h-[2px] bg-black transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <a
            href="mailto:sales@verixpro.com"
            className="relative group text-gray-700"
          >
            Contact
            <span className="absolute left-0 bottom-[-2px] w-0 h-[2px] bg-black transition-all duration-300 group-hover:w-full"></span>
          </a>
          <Link to="/faq" className="relative group text-gray-700">
            FAQs
            <span className="absolute left-0 bottom-[-2px] w-0 h-[2px] bg-black transition-all duration-300 group-hover:w-full"></span>
          </Link>
        </nav>
        <div className="flex items-center space-x-4">
          <Link to="/signin" className="relative group text-gray-700">
            Login
            <span className="absolute left-0 bottom-[-2px] w-0 h-[2px] bg-black transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link
            to="/signup"
            className="px-4 py-2 text-white bg-black rounded-full hover:bg-gray-800"
          >
            Sign Up
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;
