import React from "react";

const DeleteFileModal = ({ handleCancelDelete, handleConfirmDelete }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md shadow-lg w-96">
        <h2 className="text-xl font-bold">
          Are you sure you want to delete this file?
        </h2>
        <div className="mt-4 flex justify-between">
          <button
            onClick={handleCancelDelete}
            className="px-4 py-2 bg-gray-300 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmDelete}
            className="px-4 py-2 bg-red-600 text-white rounded-md"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteFileModal;
