import React, { useState } from "react";
import { CrossIcon } from "../../assets/icons/icons"; // assuming you have a cross icon

const RenameModal = ({ resourceName, placeholder, onClose, onSave }) => {
  const [newName, setNewName] = useState("");

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-600 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-xl">
            <img src={CrossIcon} alt="Close" />
          </button>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            New {resourceName || ""} Name
          </label>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="mt-2 p-3 w-full border border-gray-300 rounded"
            placeholder={placeholder || "Enter new name..."}
          />
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={() => onSave(newName)}
            className="bg-black text-white px-4 py-2 rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenameModal;
