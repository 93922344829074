import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  bannerTexts,
  key_features,
  whatToExpect,
} from "../../utils/constants/landingPageConstants";
import Header from "./header";
import Footer from "./footer";
import Banner from "./banner";

const LandingPage = () => {
  const [openFeatureIndex, setOpenFeatureIndex] = useState(null);

  const toggleFeature = (index) => {
    setOpenFeatureIndex((prev) => (prev === index ? null : index));
  };

  return (
    <div className="min-h-screen bg-gray-100 font-sans">
      <Header />

      <Banner
        heading={bannerTexts.landingPage.heading}
        text={bannerTexts.landingPage.subHeading}
      />

      {/* Key Features Section */}
      <motion.section
        className="py-16 bg-gray-100"
        initial={{ opacity: 0, y: 50 }} // Start below (y: 50) and hidden (opacity: 0)
        whileInView={{ opacity: 1, y: 0 }} // Fade in and move to final position (y: 0)
        viewport={{ once: false }} // Trigger only once when the section enters the viewport
        transition={{ duration: 0.8 }} // Smooth transition with easing effect
      >
        <motion.h3
          className="text-3xl font-bold text-center text-gray-800"
          initial={{ opacity: 0, y: 50 }} // Same bottom-to-top animation for heading
          whileInView={{ opacity: 1, y: 0 }} // Fade in and move to final position
          transition={{ duration: 0.8 }}
        >
          Key Features
        </motion.h3>
        <div className="flex flex-wrap justify-center gap-6 mt-10 max-w-4xl mx-auto">
          {key_features.map((feature, index) => (
            <motion.div
              key={index}
              className="flex-1 min-w-[45%] max-w-[45%]"
              initial={{ opacity: 0, y: 50 }} // Same bottom-to-top animation for feature cards
              whileInView={{ opacity: 1, y: 0 }} // Fade in and move to final position
              transition={{ duration: 0.8, delay: index * 0.1 }} // Staggered animation for each feature card
              whileHover={{
                scale: 1.05,
                y: -10,
              }}
              onClick={() => toggleFeature(index)}
            >
              <div className="p-4 bg-white rounded-lg shadow-lg hover:shadow-xl cursor-pointer">
                <h4 className="text-lg font-semibold text-gray-800">
                  {feature.title}
                </h4>
                {openFeatureIndex === index && (
                  <motion.p
                    className="mt-2 text-gray-600"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {feature.description}
                  </motion.p>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      </motion.section>

      {/* What Can You Expect Section */}
      <motion.section
        className="py-16 bg-gray-100"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
      >
        <h3 className="text-3xl font-bold text-center text-gray-800">
          What Can You Expect?
        </h3>
        <div className="grid grid-cols-2 gap-6 mt-10 max-w-4xl mx-auto">
          {whatToExpect.map((item, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center text-center"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{
                duration: 0.5,
                delay: index * 0.2,
                ease: "easeInOut",
              }}
              whileHover={{
                scale: 0.95, // Scale down the card on hover
                transition: { duration: 0.3, ease: "easeInOut" },
              }}
            >
              <motion.img
                src={item.image}
                alt={item.title}
                className="w-full max-h-[290px] mb-4"
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.2,
                  ease: "easeInOut",
                }}
              />
              <motion.h4
                className="text-lg font-semibold text-gray-800"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.2 + 0.1,
                  ease: "easeInOut",
                }}
              >
                {item.title}
              </motion.h4>
            </motion.div>
          ))}
        </div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default LandingPage;
