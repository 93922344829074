import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { motion } from "framer-motion";
import Banner from "./banner";
import { bannerTexts } from "../../utils/constants/landingPageConstants";
import { Link } from "react-router-dom";

const FAQ = () => {
  const [expanded, setExpanded] = useState(null);

  const toggleFAQ = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="bg-gray-100 flex flex-col min-h-screen">
      <Header />
      <Banner
        heading={bannerTexts.faqPage.heading}
        text={bannerTexts.faqPage.subHeading}
      />
      {/* FAQ section */}
      <motion.section
        className="py-16 px-8 max-w-3xl mx-auto"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: false }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className={`mb-6 bg-white rounded-lg shadow-lg p-6 cursor-pointer transform transition-transform ${
            expanded === 0 ? "scale-105" : "hover:scale-105"
          }`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          onClick={() => toggleFAQ(0)}
        >
          <h3 className="text-xl font-bold text-[#18181B] mb-2">
            What trades are supported?
          </h3>
          {expanded === 0 && (
            <motion.p
              className="text-gray-600 mt-2"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              VerixPro currently offers comprehensive capabilities tailored to
              the erosion control industry. This includes detecting and
              quantifying items such as:
              <ul className="list-disc pl-5 py-2">
                <li>Stabilized Construction Entrances/Exits</li>
                <li>Silt Fence</li>
                <li>Inlet Protection</li>
                <li>And More!</li>
              </ul>
              We're actively developing additional trade support for civil,
              architectural, mechanical, plumbing, and electrical disciplines.
              Stay tuned for updates as we expand our capabilities to cover all
              aspects of construction takeoff.
            </motion.p>
          )}
        </motion.div>

        <motion.div
          className={`mb-6 bg-white rounded-lg shadow-lg p-6 cursor-pointer transform transition-transform ${
            expanded === 1 ? "scale-105" : "hover:scale-105"
          }`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          onClick={() => toggleFAQ(1)}
        >
          <h3 className="text-xl font-bold text-[#18181B] mb-2">
            Can I try VerixPro for free?
          </h3>
          {expanded === 1 && (
            <motion.p
              className="text-gray-600 mt-2"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              Absolutely! Enjoy a free 30-day trial to experience VerixPro's
              powerful features with no strings attached. We do not
              automatically enroll you in a paid account. Near the end of your
              trial, our sales team will connect with you to discuss your
              experience and help transition to a paid plan if you choose to
              continue.
            </motion.p>
          )}
        </motion.div>

        <motion.div
          className={`mb-6 bg-white rounded-lg shadow-lg p-6 cursor-pointer transform transition-transform ${
            expanded === 2 ? "scale-105" : "hover:scale-105"
          }`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          onClick={() => toggleFAQ(2)}
        >
          <h3 className="text-xl font-bold text-[#18181B] mb-2">
            Do you offer a demo or training?
          </h3>
          {expanded === 2 && (
            <motion.p
              className="text-gray-600 mt-2"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              Yes, we do! VerixPro is designed to be highly intuitive, but we
              aim to make your onboarding seamless. We offer free personalized
              demos and ongoing support to ensure you maximize the software's
              potential.
              <Link to="/signup" className="text-blue-500 underline">
                Sign up for your personalized demo!
              </Link>
            </motion.p>
          )}
        </motion.div>

        <motion.div
          className={`mb-6 bg-white rounded-lg shadow-lg p-6 cursor-pointer transform transition-transform ${
            expanded === 3 ? "scale-105" : "hover:scale-105"
          }`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          onClick={() => toggleFAQ(3)}
        >
          <h3 className="text-xl font-bold text-[#18181B] mb-2">
            Can my organization schedule in-person training?
          </h3>
          {expanded === 3 && (
            <motion.p
              className="text-gray-600 mt-2"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              We provide customized in-person training sessions for
              organizations with paid accounts on a one-year commitment. Contact
              us to schedule a session tailored to your team's needs.
            </motion.p>
          )}
        </motion.div>

        <motion.div
          className={`mb-6 bg-white rounded-lg shadow-lg p-6 cursor-pointer transform transition-transform ${
            expanded === 4 ? "scale-105" : "hover:scale-105"
          }`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          onClick={() => toggleFAQ(4)}
        >
          <h3 className="text-xl font-bold text-[#18181B] mb-2">
            What if the primary account user is unavailable (e.g., on vacation)?
          </h3>
          {expanded === 4 && (
            <motion.p
              className="text-gray-600 mt-2"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              Each VerixPro user has a unique login that can only be active on
              one device at a time. If temporary access is needed for another
              team member, our support team is here to help. Reach out to us for
              assistance in such situations.
            </motion.p>
          )}
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default FAQ;
