import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { CrossIcon } from "../../assets/icons/icons";
import { requestDemoSchema } from "../../utils/schema";

const RequestDemoModal = ({ isOpen, closeModal, onSubmit }) => {
  const initialValues = {
    name: "",
    email: "",
    company: "",
    trade: "",
    items: "",
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-md relative"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-2xl font-bold mb-4 text-center">
              Request Your Customized Demo
            </h3>
            <Formik
              initialValues={initialValues}
              validationSchema={requestDemoSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => (
                <Form className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Name
                    </label>
                    <Field
                      name="name"
                      className={`w-full p-2 border ${
                        errors.name && touched.name
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                      placeholder="Enter your name"
                    />
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Email
                    </label>
                    <Field
                      name="email"
                      className={`w-full p-2 border ${
                        errors.email && touched.email
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                      placeholder="Enter your email"
                      type="email"
                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Company or Organization
                    </label>
                    <Field
                      name="company"
                      className={`w-full p-2 border ${
                        errors.company && touched.company
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                      placeholder="Enter your company or organization"
                    />
                    <ErrorMessage
                      component="div"
                      name="company"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Trade
                    </label>
                    <Field
                      name="trade"
                      className={`w-full p-2 border ${
                        errors.trade && touched.trade
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                      placeholder="Enter your trade"
                    />
                    <ErrorMessage
                      component="div"
                      name="trade"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">
                      What type of items do you typically bid on or use software
                      takeoff for?
                    </label>
                    <Field
                      name="items"
                      className={`w-full p-2 border ${
                        errors.items && touched.items
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded`}
                      placeholder="Describe items"
                    />
                    <ErrorMessage
                      component="div"
                      name="items"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full py-2 text-white bg-black rounded-lg hover:bg-gray-800"
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <img src={CrossIcon} alt="Close" />
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RequestDemoModal;
