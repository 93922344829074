// utils/errorHandler.js
import { showAllNotifications } from "./notificationHelper";
import { logout } from "../redux/slices/authSlice";
import { notifications } from "./notificationConstants";

export const handleApiError = (error, dispatch, navigate) => {
  if (!error?.error) {
    showAllNotifications(notifications.unexpectedError);
    return;
  }

  const { code, message } = error.error;

  switch (code) {
    case "AUTHENTICATION_ERROR":
      showAllNotifications(message || notifications.authenticationError);
      dispatch(logout());
      break;
    case "NOT_FOUND":
      showAllNotifications(message || notifications.notFound);
      navigate("/projects");
      break;
    case "AUTHORIZATION_ERROR":
      showAllNotifications(message || notifications.authorizationError);
      navigate("/projects");
      break;
    default:
      showAllNotifications(message || notifications.unexpectedError);
  }
};
