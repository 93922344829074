import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Banner = ({ heading, text }) => {
  return (
    <div className="relative overflow-hidden">
      <Content heading={heading} text={text} />
      <FuzzyOverlay />
    </div>
  );
};

const FuzzyOverlay = () => {
  return (
    <motion.div
      initial={{ transform: "translateX(-10%) translateY(-10%)" }}
      animate={{
        transform: "translateX(10%) translateY(10%)",
      }}
      transition={{
        repeat: Infinity,
        duration: 0.2,
        ease: "linear",
        repeatType: "mirror",
      }}
      style={{
        backgroundImage: 'url("/black-noise.png")',
      }}
      className="pointer-events-none absolute -inset-[100%] opacity-[15%]"
    />
  );
};

const Content = ({ heading, text }) => {
  return (
    <motion.div
      className="relative grid h-screen place-content-center space-y-6 bg-neutral-950 p-8"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      {/* Heading */}
      <motion.p
        className="text-center text-6xl font-black text-neutral-50"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.8 }}
      >
        {heading}
      </motion.p>

      {/* Text */}
      <motion.p
        className="text-center text-neutral-400"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.8 }}
      >
        {text}
      </motion.p>

      {/* Button */}
      <motion.div
        className="flex items-center justify-center gap-3"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <Link to="/pricing">
          <button className="w-fit bg-neutral-200 px-4 py-2 font-semibold text-neutral-700 transition-colors hover:bg-neutral-50">
            Get Started
          </button>
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default Banner;
