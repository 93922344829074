import {
  Collaboration,
  FasterTurnaround,
  ReduceErrors,
  SaveTime,
} from "../../assets/images/images";

export const key_features = [
  {
    title: " AI-Enhanced Takeoff Automation",
    description:
      "Leverage advanced AI technology to automate the takeoff process. VerixPro identifies, highlights, quantifies, and reports all items of interest based on your unique requirements. This automation reduces manual input, minimizes errors, and saves valuable time for estimators.",
  },
  {
    title: "User-Friendly Interface",
    description:
      "Built with ease of use in mind, VerixPro features an intuitive interface that minimizes the learning curve and maximizes productivity. Whether you're a seasoned professional or new to the field, VerixPro ensures a smooth and efficient experience.",
  },
  {
    title: "Cloud-Based for Seamless Collaboration",
    description:
      "Access your projects from anywhere with VerixPro's fully cloud-based platform. Seamlessly collaborate with team members and enjoy scalability that grows with your business—no need for costly infrastructure updates.",
  },
  {
    title: "Robust Data Security",
    description:
      "Protect your sensitive construction data with VerixPro’s industry-leading security measures. Our platform integrates the latest encryption protocols, continuous monitoring, and advanced safeguards to ensure your information stays secure.",
  },
  {
    title: "Scalable and Reliable",
    description:
      "Experience uninterrupted service with live updates and zero downtime. VerixPro is designed to handle increased workloads and expand with your user base, delivering consistent performance and reliability as your business grows.",
  },
];

export const pricingTiers = [
  {
    title: "Free Trial",
    price: "$0 for 30 Days",
    info: "Try VerixPro Free for 30 Days",
    features: ["No Strings Attached", "No Automatic Enrollment"],
    button: "Choose 30-Day Trial",
  },
  {
    title: "Pro",
    price: "$250/month",
    info: "$2700/year on upfront payment",
    features: [
      "AI Driven Takeoffs",
      "PDF Editing",
      "Email Support",
      "Unlimited projects",
    ],
    button: "Choose Pro",
  },
  {
    title: "Enterprise",
    price: "Contact Us",
    info: "Reach out to us for more information",
    features: [
      "All Pro Features",
      "Customized solutions",
      "Ideal for Teams",
      "Enhanced Metrics",
    ],
    button: "Contact Sales",
  },
];

export const whatToExpect = [
  { title: "Save Time on Takeoffs", image: SaveTime },
  {
    title: "Streamline Collaboration with Team Members",
    image: Collaboration,
  },
  {
    title: "Increase Accuracy and Reduce Errors",
    image: ReduceErrors,
  },
  {
    title: "Achieve Faster Project Turnaround",
    image: FasterTurnaround,
  },
];

export const bannerTexts = {
  landingPage: {
    heading:
      "Revolutionize your Construction Takeoffs with AI-Powered Precision",
    subHeading:
      "Automate takeoffs, minimize errors, and maximize efficiency with VerixPro",
  },
  pricingPage: {
    heading: "Choose the Perfect Plan for Your Needs",
    subHeading:
      "Flexible pricing options tailored for individuals, professionals, and enterprises.",
  },
  faqPage: {
    heading: "Your Questions, Answered",
    subHeading:
      "Find clear and concise answers to common queries about VerixPro.",
  },
  aboutUsPage: {
    heading: "About Us",
    subHeading:
      "At VerixPro, we’re revolutionizing construction takeoff by bridging the gap between traditional processes and cutting-edge AI technology.",
    journeyText1:
      "Founded by Mark Poehl, a seasoned Senior Project Manager with over a decade of experience in environmental consulting and construction compliance, VerixPro was born from a firsthand understanding of the inefficiencies and challenges estimators face every day.",
    journeyText2:
      "While managing SWPPP plans, overseeing BMP installations, and directing weekly inspections for hundreds of projects across Texas, Mark witnessed the growing demand for precision and efficiency in construction planning. Simultaneously, as a Wagyu cattle rancher and entrepreneur, he cultivated a passion for blending innovation with practicality—a mindset that drives VerixPro’s mission today.",
    vision:
      "The idea for VerixPro sparked when Mark realized how construction takeoff, a critical phase in project estimation, had been overlooked in the wave of AI advancements transforming other industries. Determined to modernize this essential process, he envisioned a solution that would leverage AI to reduce manual input, minimize errors, and save estimators valuable time while delivering unmatched accuracy.",
    mission:
      "VerixPro is the culmination of years of observation, problem-solving, and relentless determination. By combining advanced AI technology with an intuitive, user-focused design, our platform empowers construction professionals to achieve more with less effort.",
  },
};

export const footerText =
  "With VerixPro, innovation meets practicality, delivering solutions that truly work for you. Let’s build a smarter, more efficient future for construction.";
