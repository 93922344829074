import api from "../../api/api";
import { PROJECTS_PER_PAGE } from "../../utils/mainHelper";

const projectService = {
  createProject: (data) => api.post("/projects", data),
  getProjects: (page = 1, perPage = PROJECTS_PER_PAGE) =>
    api.get(`/projects?page=${page}&per_page=${perPage}`),
  getProjectById: (id) => api.get(`/projects/${id}`),
  updateProject: (id, data) => api.put(`/projects/${id}`, data),
  deleteProject: (id) => api.delete(`/projects/${id}`),
  trackProjectOpened: (id) => api.post(`/projects/${id}/track_opened_at`),
  duplicateProject: (id) => api.post(`/projects/${id}/duplicate`),
  updateProjectDetails: (id, data) =>
    api.put(`/projects/${id}/update_project_details`, data),
  addProjectFiles: (id, data) =>
    api.post(`/projects/${id}/append_project_files`, data),
  deleteProjectFile: (id, data) =>
    api.delete(`/projects/${id}/delete_project_file`, { data }),
  renameProjectFile: (id, data) =>
    api.put(`/projects/${id}/rename_project_file`, data),
  trackProjectFileOpened: (id, data) =>
    api.post(`/projects/${id}/track_file_accessed_at`, data),
};

export default projectService;
