import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlusCircle, ProjectOptions } from "../../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { formatFileSize } from "../../utils/projects/fileSizeHelper";
import { useFormik } from "formik";
import {
  handleAddProjectFiles,
  handleDeleteProjectFile,
  handleGetProjectById,
  handleRenameProjectFile,
  handleTrackProjectFileOpened,
} from "../../redux/slices/projectSlice";
import { handleApiError } from "../../utils/errorHandler";
import DeleteFileModal from "../../components/projects/DeleteFileModal";
import RenameModal from "../../components/projects/RenameProjectModal";
import { showAllNotifications } from "../../utils/notificationHelper";
import { isAuthorizedToAccessFiles } from "../../utils/projects/projects";
import { timeAgo } from "../../utils/timeAgo";

const ProjectFiles = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeFile, setActiveFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileToRename, setFileToRename] = useState(null);
  const optionsRef = useRef(null);

  const { project } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  useEffect(() => {
    if (id && isEmpty(project)) {
      dispatch(handleGetProjectById(id))
        .unwrap()
        .catch((err) => {
          handleApiError(err, dispatch, navigate);
        });
    }
  }, [id, dispatch]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (optionsRef.current && !optionsRef.current.contains(e.target)) {
        setActiveFile(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionsClick = (e, fileIndex) => {
    e.stopPropagation();
    setActiveFile(activeFile === fileIndex ? null : fileIndex);
  };

  const initialValues = {
    new_files: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const formData = new FormData();
      values.new_files.forEach((file) => {
        formData.append("project[project_files][]", file);
      });

      dispatch(handleAddProjectFiles({ id: project.id, data: formData }))
        .unwrap()
        .then(() => formik.setFieldValue("new_files", []))
        .catch((error) => {
          handleApiError(error, dispatch, navigate);
        });
    },
  });

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    formik.setFieldValue("new_files", [...formik.values.new_files, ...files]);
  };

  const handleDeleteFile = (file) => {
    setFileToDelete(file);
    setIsModalOpen(true);
  };

  const handleRenameFile = (file) => {
    setFileToRename(file);
    setIsRenameModalOpen(true);
  };

  const handleRename = (newName) => {
    if (!newName.trim()) {
      showAllNotifications("File name cannot be blank");
      return;
    }

    if (fileToRename) {
      dispatch(
        handleRenameProjectFile({
          id: project.id,
          data: { file_id: fileToRename.id, new_name: newName },
        })
      )
        .unwrap()
        .then(() => {
          setIsRenameModalOpen(false);
          setFileToRename(null);
        })
        .catch((error) => {
          handleApiError(error, dispatch, navigate);
        });
    }
  };

  const handleConfirmDelete = () => {
    if (fileToDelete) {
      dispatch(
        handleDeleteProjectFile({
          id: project.id,
          data: { file_id: fileToDelete.id },
        })
      )
        .unwrap()
        .then(() => {
          setIsModalOpen(false);
          setFileToDelete(null);
        })
        .catch((error) => {
          handleApiError(error, dispatch, navigate);
        });
    }
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
    setFileToDelete(null);
  };

  const handleTrackFileOpened = (file_id) => {
    dispatch(
      handleTrackProjectFileOpened({
        id: project.id,
        data: { file_id: file_id },
      })
    )
      .unwrap()
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="w-[92%] mx-auto">
      <h1 className="font-inter text-[24px] font-bold text-[#18181B] mb-10">
        {project?.name || "Project Files"}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {project?.project_files_urls?.map((file, index) => (
          <div
            key={index}
            className="border border-[#E4E4E7] rounded-[8px] shadow-md hover:shadow-lg relative"
          >
            {/* File Preview */}
            <iframe
              title={`Project File ${index}`}
              className="w-full p-3"
              src={file.url}
            ></iframe>

            {/* Separator Line */}
            <div className="border border-[#E4E4E7]"></div>

            {/* File Details */}

            <div className="py-4 px-6 flex items-start justify-between h-[35%]">
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                {/* File Name and Details */}
                <div className="flex-1 min-w-0">
                  <h3 className="font-inter text-[1rem] font-semibold text-[#18181B] mb-2 break-words max-w-full">
                    {file.filename}
                  </h3>
                  <p className="text-[#71717A] text-[16px] mb-1">
                    {file.byte_size ? `${formatFileSize(file.byte_size)}` : ""}{" "}
                    | pdf
                  </p>
                  <p className="text-[#71717A] text-[16px]">
                    {file.accessed_at
                      ? `Opened ${timeAgo(file.accessed_at)}`
                      : null}
                  </p>
                </div>
              </a>

              {/* Options Button */}
              <div className="ml-4 flex-shrink-0">
                <button
                  className="text-[#71717A] p-2 hover:bg-[#f0f0f5] rounded-full"
                  onClick={(e) => handleOptionsClick(e, index)}
                >
                  <img src={ProjectOptions} alt="Options" />
                </button>

                {/* Dropdown Menu */}
                {activeFile === index && (
                  <div
                    ref={optionsRef}
                    className="absolute right-0 mt-2 bg-white border border-[#E4E4E7] rounded-lg shadow-lg w-48 z-10"
                  >
                    <ul className="text-sm text-[#18181B]">
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <li
                          onClick={() => handleTrackFileOpened(file.id)}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Open
                        </li>
                      </a>
                      {isAuthorizedToAccessFiles(project, user) && (
                        <>
                          <li className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer">
                            Edit
                          </li>
                          <li
                            onClick={() => handleRenameFile(file)}
                            className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                          >
                            Rename
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                            onClick={() => handleDeleteFile(file)}
                          >
                            Delete
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Display newly uploaded files */}
      {formik.values.new_files.length > 0 && (
        <label className="block font-inter text-sm font-medium leading-5 text-[#18181B] mt-6">
          Unsaved Files
        </label>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 py-6">
        {formik.values.new_files.map((file, index) => {
          const fileUrl = URL.createObjectURL(file);
          return (
            <div
              key={index}
              className="border rounded-md p-4 shadow-md hover:shadow-lg"
            >
              <iframe
                src={fileUrl}
                title={`Uploaded File ${index}`}
                className="w-full h-48"
              ></iframe>
              <div className="border border-[#E4E4E7]"></div>
              <h3 className="font-inter text-[1rem] font-semibold text-[#18181B] mb-2 break-words max-w-full mt-2">
                {file.name}
              </h3>
              <p className="text-[#71717A] text-[16px] mt-2">
                {formatFileSize(file.size)}
              </p>
            </div>
          );
        })}
      </div>

      <div className="mt-6">
        <div
          className="mt-4 p-12 border-dotted border-2 border-gray-300 rounded-xl flex flex-col items-center justify-center text-center !cursor-pointer w-full md:w-1/4"
          onClick={() => document.getElementById("fileUpload").click()}
        >
          <img src={PlusCircle} alt="UploadFileIcon" className="mb-4" />
          <p className="text-[#18181B] text-[16px] font-inter font-normal leading-[44px]">
            Upload New File
          </p>
          <input
            id="fileUpload"
            name="new_files"
            type="file"
            multiple
            className="hidden"
            onChange={handleFileUpload}
          />
        </div>

        <div className="py-6 mb-[100px]">
          {formik.values.new_files.length > 0 && (
            <button
              onClick={formik.handleSubmit}
              type="submit"
              className="flex flex-end px-6 py-2 bg-black text-white rounded-lg"
            >
              Save
            </button>
          )}
        </div>
      </div>

      {isModalOpen && (
        <DeleteFileModal
          handleCancelDelete={handleCancelDelete}
          handleConfirmDelete={handleConfirmDelete}
        />
      )}

      {isRenameModalOpen && (
        <RenameModal
          resourceName="File"
          placeholder="Enter new file name"
          onClose={() => setIsRenameModalOpen(false)}
          onSave={handleRename}
        />
      )}
    </div>
  );
};

export default ProjectFiles;
