import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LoginImg, LogoImg } from "../../assets/images/images";
import { newPasswordIcon } from "../../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { handleNewPassword } from "../../redux/slices/authSlice";

const NewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("reset_password_token");

  const [form, setForm] = useState({
    password: "",
    passwordConfirmation: "",
  });

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) navigate("/projects");
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(handleNewPassword({
      data: {
        user: {
          reset_password_token: token,
          password: form.password,
          password_confirmation: form.passwordConfirmation,
        }
      },
      navigate
    }));
  };

  return (
    <div className="w-full h-[100vh] px-12 py-14" style={{ backgroundImage: `url(${LoginImg})`, backgroundSize: "107%", backgroundPositionX: "right", backgroundPositionY: "top" }}>
      <div className="w-full">
        <img src={LogoImg} alt="VerixPro" />
      </div>
      <div className="w-full flex justify-center items-center">
        <form onSubmit={handleSubmit} className="w-full max-w-[450px] mt-20 bg-white py-12 px-7 rounded-lg">
          <div className="w-full mb-4 flex justify-center">
            <img src={newPasswordIcon} alt="lock" />
          </div>
          <div className="w-full text-center text-2xl font-semibold pb-5 mb-5 border-b border-b-[1px_solid_#E4E4E7]">
            Create New Password
          </div>
          <div className="w-full px-3">
            <div className="w-full mb-6 text-[#71717A]">
              Your New Password Must Be Different From The Previous Used Password
            </div>
            <div className="w-full mb-6">
              <div className="w-full text-sm font-medium mb-2">
                New Password <span className="text-red-500">*</span>
              </div>
              <input
                type="password"
                name="password"
                className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                placeholder="Enter password"
                value={form.password}
                onChange={handleChange}
              />
            </div>
            <div className="w-full mb-6">
              <div className="w-full text-sm font-medium mb-2">
                Confirm Password <span className="text-red-500">*</span>
              </div>
              <input
                type="password"
                name="passwordConfirmation"
                className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                placeholder="Enter confirm password"
                value={form.passwordConfirmation}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-black text-white text-sm h-10 flex justify-center items-center rounded-md"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
