import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { handleGetProjectById } from "../../redux/slices/projectSlice";
import {
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineHome,
} from "react-icons/ai";
import { projects } from "../../utils/projects/projectConstants";
import { handleApiError } from "../../utils/errorHandler";
import { formatFileSize } from "../../utils/projects/fileSizeHelper";
import { isAuthorizedToAccessFiles } from "../../utils/projects/projects";
import { DefaultProfileImg } from "../../assets/images/images";

const ProjectDetails = () => {
  const { id } = useParams();

  const { project } = useSelector((state) => state.projects);
  const { user, userRoles } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(handleGetProjectById(id))
        .unwrap()
        .catch((err) => {
          handleApiError(err, dispatch, navigate);
        });
    }
  }, [id, dispatch]);

  const handleViewFiles = () => {
    if (isAuthorizedToAccessFiles(project, user)) {
      navigate(`/projects/${project.id}/files`, {
        state: { project },
      });
    }
  };

  return (
    <>
      {project && Object.keys(project).length > 0 && (
        <div className="p-8 bg-gray-100 min-h-screen flex flex-col items-center">
          <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg">
            <div className="relative">
              <img
                src={
                  project?.project_image_url || projects[project.id % 6].image
                }
                alt={project.name}
                className="w-full h-64 object-cover"
              />
              <div className="absolute bottom-4 left-4">
                <h1 className="bg-white bg-opacity-75 px-4 py-2 rounded-md text-2xl font-semibold text-gray-800">
                  {project.name}
                </h1>
              </div>
              {(userRoles.includes("Administrator") ||
                userRoles.includes("AccountManager")) && (
                <button
                  className="absolute top-4 right-4 bg-[#18181B] text-white px-4 py-2 rounded-md hover:bg-white hover:text-black"
                  onClick={() =>
                    navigate("/projects/new", {
                      state: { project, type: "Edit" },
                    })
                  }
                >
                  Edit
                </button>
              )}
            </div>

            {/* Project Details */}
            <div className="p-6 space-y-4">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-gray-800">
                  Project Type:{" "}
                  <span className="font-normal">{project.project_type}</span>
                </h2>
                <p className="text-sm text-gray-500">
                  <AiOutlineCalendar className="inline-block mr-1" />
                  Created on:{" "}
                  {new Date(project.created_at).toLocaleDateString()}
                </p>
              </div>

              <p className="text-gray-600">
                {project.description || "No description available."}
              </p>

              {/* Client Info */}
              <div className="border-t pt-4">
                <h3 className="text-lg font-medium text-gray-700">
                  Client Information
                </h3>
                <div className="mt-2 flex items-center space-x-4">
                  <AiOutlineUser className="text-xl text-gray-600" />
                  <span className="text-gray-800">
                    {project.client_name || "No client name available"}
                  </span>
                </div>
                {project.client_information && (
                  <p className="mt-2 text-gray-600">
                    {project.client_information}
                  </p>
                )}
              </div>

              {/* Address Section */}
              <div className="border-t pt-4">
                <h3 className="text-lg font-medium text-gray-700">Address</h3>
                <div className="flex items-center space-x-4 mt-2">
                  <AiOutlineHome className="text-xl text-gray-600" />
                  <span className="text-gray-800">
                    {project.address || "No address available"}
                  </span>
                </div>
              </div>
            </div>

            {/* Team Members Section */}
            <div className="p-6 border-t">
              <h3 className="text-lg font-medium text-gray-700 mb-4">
                Team Members
              </h3>
              <div className="flex flex-wrap gap-4 relative z-10">
                {project.team_members.map((member, index) => (
                  <div key={index} className="relative group">
                    <img
                      src={user?.profile_picture || DefaultProfileImg}
                      alt={member || "Profile"}
                      className="w-12 h-12 rounded-full"
                    />
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 bg-gray-800 text-white text-sm px-3 py-1 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity z-50 pointer-events-none">
                      {member}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Project Files Section */}
            {project.project_files_urls &&
              project.project_files_urls.length > 0 && (
                <div className="p-6 border-t">
                  <button
                    onClick={handleViewFiles}
                    className="text-lg font-medium text-gray-700 mb-4 hover:underline"
                  >
                    Project Files
                  </button>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {project.project_files_urls.map((file, index) => (
                      <div
                        key={index}
                        className="border rounded-lg p-4 bg-gray-50 shadow-sm cursor-pointer"
                        onClick={() => setSelectedFile(file)}
                      >
                        {/* <Worker workerUrl={workerUrl}>
                          <Viewer
                            fileUrl={
                              "https://level-sol-440022-t2.uc.r.appspot.com/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBLZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a2c70b9dc679aab51e85a083f4a66703d5c88f20/VERIX%20PRO%20-%20Feature%20Breakdown%20(3).pdf"
                            }
                            plugins={[fullScreenPluginInstance]}
                          />
                        </Worker> */}
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="text-sm mb-3 underline">
                            View{" "}
                            {file.byte_size
                              ? `(${formatFileSize(file.byte_size)})`
                              : ""}
                          </p>
                          <iframe
                            title={`Project File ${index}`}
                            className="w-full"
                            src={file.url}
                          ></iframe>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {/* Go Back Button */}
            <div className="flex justify-center mt-4 bg-gray-50">
              <button
                onClick={() => navigate("/projects")}
                className="text-black py-2 px-4 rounded-md hover:underline"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
