import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const AUTH_LINKS = [
    "/signin",
    "/signup",
    "/reset-password",
    "/verify-email",
    "/new-password",
  ];

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated && AUTH_LINKS.includes(pathname)) navigate("/projects");
  }, [isAuthenticated, navigate, pathname]);

  return <>{children}</>;
};

export default PublicRoute;
