import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { motion } from "framer-motion";
import {
  bannerTexts,
  pricingTiers,
} from "../../utils/constants/landingPageConstants";
import { NavLink, useNavigate } from "react-router-dom";
import Banner from "./banner";
import RequestDemoModal from "../../components/projects/RequestDemoModal";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../utils/errorHandler";
import { handleRequestDemo } from "../../redux/slices/contactSlice";

const Pricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleSubmit = (values) => {
    dispatch(handleRequestDemo({ contact: values }))
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  };

  return (
    <div className="bg-gray-100">
      <Header />
      <div className="min-h-screen font-sans">
        <Banner
          heading={bannerTexts.pricingPage.heading}
          text={bannerTexts.pricingPage.subHeading}
        />

        {/* Pricing Tiers Section */}
        <motion.section
          className="py-16"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        >
          <div className="flex flex-wrap justify-center gap-8 max-w-6xl mx-auto">
            {pricingTiers.map((tier, index) => (
              <motion.div
                key={index}
                className="flex flex-col justify-between max-w-[300px] p-6 bg-white rounded-lg shadow-lg hover:shadow-xl"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                whileHover={{
                  scale: 1.05,
                }}
              >
                <div>
                  <h3 className="text-2xl font-bold text-black text-center mb-4">
                    {tier.title}
                  </h3>
                  <p className="text-center text-3xl font-extrabold text-gray-800 mb-6">
                    {tier.price}
                  </p>
                  <p className="text-center text-sm font-extrabold text-gray-600 mt-2 mb-6">
                    {tier.info}
                  </p>
                  <ul className="text-gray-600 space-y-3">
                    {tier.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center">
                        <span className="w-4 h-4 bg-green-500 rounded-full mr-2"></span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <NavLink to="/signup">
                  <button className="mt-6 w-full py-2 text-white bg-black hover:bg-gray-800 rounded-full">
                    {tier.button}
                  </button>
                </NavLink>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <div className="text-center my-8">
          <motion.div
            className="text-center py-8"
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            <h3 className="text-3xl font-bold text-gray-800 my-6">
              Still not convinced? Get a free customized demo to see VerixPro in
              action!
            </h3>
            <button
              onClick={openModal}
              className="px-6 py-2 font-medium bg-black text-white w-fit transition-all shadow-[3px_3px_0px_white] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px]"
            >
              Request Demo
            </button>
          </motion.div>
        </div>

        {/* Modal Component */}
        <RequestDemoModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          onSubmit={handleSubmit}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
